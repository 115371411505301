/* EARTH IMAGE SECTION *********************/
.hero-image-text-about-wrap {
  width: 100%;
  top: 43%;
  color: white;
  font-size: 3.1vw;
  font-weight: 300;
  text-align: center;
  position: absolute;
  /* padding: 0 2rem; */
  text-shadow: 1px 1px 2px black;
}

.hero-image-text-about {
  max-width: 68.75rem;
  margin: 0 auto;
  padding: 0 2rem;
}

/* .hero-image-text-about {
  top: 43%;
  color: white;
  font-size: 3.1vw;
  font-weight: 300;
  text-align: center;
  max-width: 68.75rem;
  position: absolute;
  margin: 0 auto;
  padding: 0 2rem;
  text-shadow: 1px 1px 2px black;
} */

/* MOBILE && PORTRAIT */
@media (orientation: portrait) {
  .hero-image-text-about-wrap {
    top: 31%;
  }

	.hero-image-text-about.mobile {
    font-size: 1rem;
  }
}


/* MAIN CONTENT SIDEBAR *********************/
li.management-li {
  font-size: .7rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #0D3C96;
  border: none !important;
  padding-left: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

li.management-li:hover {
  color: gray;
  cursor: pointer;
}


li.management-li.active {
  color: gray;
}

li.management-li.last {
  /* padding-bottom: .5rem; */
  padding-bottom: 1rem;
}

li.management-li.first {
  padding-top: 0;
}

.ownership-chart-img {
  width: 100%;
}


/* RIGHT SIDE CONTENT************/
.board-officers-wrap {
  display: flex;
  justify-content: space-between;
}

.board, .officers {
  color: #0D3C96;
  margin-bottom: 1rem;
}

.board-ul li {
  margin-bottom: 3rem;
}

.title-li {
  margin-bottom: 2rem;
  color: gray;
  font-weight: 300;
}