
.sidebar-contact-li, 
.sidebar-address-li,
.sidebar-address-li a,
.contact-form,
.address-note,
.contact-p,
.contact-p span {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .75rem;
}

.sidebar-wrap-contact {
  min-width: 13rem;
  width: 13rem;
  margin-right: 1rem;
}

.sidebar-contact-li {
  padding: 1rem 0;
  border-top: 1px solid lightgray;
  color: #0D3C96;
}

.sidebar-address-li {
  margin-bottom: 1rem;
  line-height: .85rem;
  color: black !important;
}

.sidebar-address-li a {
  color:#0D3C96;
  text-decoration: none;
}

.sidebar-address-li a:hover {
  color: gray;
}


/* CONTACT FORM */
.contact-form,
.contact-fields {
  margin-bottom: 1rem;
}

.contact-fields {
  background: #E1E1E1;
  border-style: none;
}

#message-area {
  /* width: 100%;	 */
  width: -webkit-fill-available;
  background: #E1E1E1;
}

.antispam {
  display: none;
}

.contact-p span {
  color: #0D3C96;
}


/* IF Screen Width < 800px */
@media (max-width: 800px) {
  .sidebar-wrap-contact {
    flex-basis: auto;
    min-width: 100%;
  }
}


/* MOBILE LANDSCAPE */
@media (orientation: landscape) {
  .sidebar-wrap-contact.mobile {
    min-width: 12rem;
    width: fit-content;
  }
}