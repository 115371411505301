
/* NAV BAR ********************************************************************/
.navbar {
  top: 0;
  left: 0;
  width: 100%;
  background: #001e2f;
  /* height: 4.6rem; */
  height: 100px;
}

.navbar__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;

  padding: 0 2rem;
  /* max-width: 1100px; */
  max-width: 68.75rem;
  margin: 0 auto;
}

.navbar__logo {
  /* width: 200px; */
  width: 12.5rem;
  margin-right: 1rem;
}

.navbar__logo img {
  width: 100%;
}

/* class element selector- selects all elements inside class */
.navbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.navbar__top-items {
  flex-basis: 75%;
}

.navbar__top-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, sans-serif;
  justify-content: space-between;
}

.navbar__top-items li a {
  text-decoration: none;
  color: #C4C4C4;
  /* font-size: 13px; */
  font-size: .8125rem;
}

.navbar__top-items li a:hover,
.navbar__top-items li a:active {
  color: white;
}

.navbar__top-items li a.active {
  color: white;
}

#navbar-blue-bottom {
  /* height: 1.5625rem; */
  height: 25px;
  width: 100%;
  background: #084690;    

  /* covid update */
  text-align: center;
}


/* COVID UPDATE ***************************************************************/
.covid-update-navbar {
  color: white;
  font-size: .8125rem;
  padding-top: .35rem;
}

.covid-update-navbar:hover {
  cursor: pointer;
  color: red;
}

.covid-modal {
	position: absolute;
  margin: 3rem 2rem;
  background: white;
}

.covid-overlay {
	position: fixed;
  background-color: #00000094;
  top: 0;
  left: 0;
	right: 0;
  bottom: 0;
  overflow:auto;
}

.covid-update {
	color: black;
  font-size: 1rem;
	padding: 1rem 2rem 2rem 2rem;
}

.close-covid-modal {
  margin: 1rem 2rem;
  padding: 0;
  border: none;
  font-size: 1.3rem;
  background: transparent;
}    

.close-covid-modal:hover {
  cursor: pointer;
}


/* MOBILE */
.close-covid-modal.mobile {
  margin: 1rem;
}

.covid-update.mobile {
  font-size: 1rem;
  padding: 0 1rem 1rem 1rem;
}

.covid-overlay.mobile {
  width: 100%;
  height: 100%; 
  overflow: scroll;
}
/* ****************************************************************************/



/* IF MOBILE && PORTRAIT, don't show navbar */
@media (orientation: portrait) {
  .navbar__top-items.mobile {
    display: none;
  }

  .navbar__logo.mobile {
    margin: 0 auto;
  }
}

/* IF MOBILE && LANDSCAPE, reduce font sizes */
@media (orientation: landscape) {
  .navbar__logo.mobile {
    width: 10rem;
  }

  .navbar__top.mobile {
    padding: 0 1rem;
    height: 55px;
  }

  .navbar__top-items.mobile li a {
    font-size: 10px;
  }

  .navbar.mobile {
    height: 80px;
  }
}

/* IF DESKTOP && window width < 900px, reduce font size */
@media (max-width: 900px) {
  .navbar__top-items.desktop li a {
    font-size: 11px;
  }
}

/* IF DESKTOP && window width < 800px */
@media(max-width: 800px) {
  .navbar__top-items.desktop {
    display: none;
  }

  .navbar__logo.desktop {
    margin: 0 auto;
  }
}







/* HamburgerButton ************************************************************/
.hamburger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.2rem;
  width: 1.25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.hamburger-button:focus {
  outline: none;
}

.hamburger-button__line {
  width: 1.25rem;
  /* height: 2px; */
  height: .125rem;
  background: white;
}

/* IF MOBILE && LANDSCAPE, don't show hamburger */
@media (orientation: landscape) {
  .hamburger-button.mobile  {
    display: none;
  }
}

/* IF DESKTOP && window width > 800px, don't show hamburger */
/* @media (min-width: 769px) { */
@media (min-width: 800px) {
  .hamburger-button.desktop {
    display: none;
  }
}







/* SIDE DRAWER NAV ************************************************************/
.close-drawer {
  margin: 1rem 2rem;
  padding: 0;
  border: none;
  font-size: 1.3rem;
  background: transparent;
}    

.close-drawer:hover {
  cursor: pointer;
}

.side-drawer {
  height: 100%;
  background: white;
  /* drop shadow on sidebar */
  box-shadow: .125rem 0rem .1875rem rgba(0, 0, 0, .5);
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 75%;
  max-width: 25rem;
  z-index: 200; 
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0%);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.side-drawer a {
  color: black;
  text-decoration: none;
  font-size: .85rem;
  font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, sans-serif;
  border-bottom: .0625rem solid #80808052;
  margin: 0rem 2rem;
  padding: 1.85rem 0rem;
  display: block;
}

.side-drawer li:hover,
.side-drawer li:active {
  background: rgb(4, 141, 245);
  cursor: pointer;
}

/* if window > 769px, don't display side drawer */
/* @media (min-width: 769px) { */
@media (min-width: 48.0625rem) {
  .side-drawer {
    display: none;
  }

  .navbar__logo {
    margin-left: 0;
  }

  .backdrop {
    display: none;
  }
}




/* BACKDROP *******************************************************************/
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}