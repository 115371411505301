/* CSS RESET */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/* CUSTOM *********************************************************************/
html, body, #root {
  height: 100%;
	width: 100%;
}

html {
  min-height: 100%;
  
  /* Fixes font-size text-inflation algorithm on mobile */
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* Imports NavBar, HamburgerButton, SideDrawer, Backdrop */
@import "./styles/navbar.css";
@import "./styles/home.css";
@import "./styles/footer.css";
@import "./styles/about.css";
@import "./styles/services.css";
@import "./styles/phc.css";
@import "./styles/press.css";
@import "./styles/contact.css";
@import "./styles/energy.css";

@font-face {
	font-family: 'Eurostile';
	font-style: normal;
	font-weight: normal;
	src: local('Eurostile'), url('eurostile.woff') format('woff');
}


/* HERO IMAGE STYLES **********************************************************/
/* These Styles get applied to the following pages: */
/* ABOUT, SERVICES, PHC, MONTEMAR, ENERGY, PRESS, CONTACT  */
.hero-image-wrap {        
  position: relative;
}

.hero-image {
  width: 100%;
}

@media (orientation: landscape) {
  .hero-image.mobile {
    height: 75vh;
  }
}


/* MAIN CONTENT STYLES ********************************************************/
/* These Styles get applied to the following pages: */
/* ABOUT, SERVICES, PHC, MONTEMAR, PRESS, CONTACT, ENERGY */
.body-wrap-outer {
  width: 100%;
  margin-top: 1rem;
}

.body-wrap-inner {
  display: flex;
  /* margin: 0 2rem; */

  padding: 0 2rem;
  max-width: 68.75rem;
  margin: 0 auto;
}

/* SIDEBAR ****************/
.sidebar-wrap {
  min-width: 13rem;
  width: 13rem;
  margin-right: 1rem;
}

.sidebar-h1-title {
  margin-bottom: 1rem;
  font-size: .9rem;
}

.sidebar-ul {
  border-bottom: 1px solid lightgray;
} 
.sidebar-active-li,
.sidebar-inactive-li,
.subsidiary-website-li {            /* PHC, MONTEMAR PAGE ONLY */
  border-top: 1px solid lightgray;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .75rem;
  padding: 1rem 0;
}

.sidebar-active-li:hover,
.sidebar-inactive-li:hover {
  color: gray;
  cursor: pointer;
}

.sidebar-active-li,
.subsidiary-website-li a:hover,     /* PHC, MONTEMAR PAGE ONLY */
.content-wrap a:hover {             /* PHC, MONTEMAR PAGE ONLY */ 
  color: gray !important;
}

.sidebar-inactive-li {
  color: #0D3C96;
}


/* RIGHT SIDE CONTENT ************/
.content-wrap {
  width: 100%;
}

.content-wrap a {                   /* PHC, MONTEMAR PAGE ONLY */
  color: #0D3C96;
}

.content-wrap h2 {
  font-family: Eurostile, "Michroma", 'Jura', sans-serif;
  font-size: 1.5rem;
  margin-bottom: .5rem;
  /* padding-bottom: .25rem; */
  padding-bottom: .38rem;
  color: #0D3C96;
  border-bottom: 1px solid lightgrey;
}

.content-p,
.content-wrap a {                   /* PHC, MONTEMAR PAGE ONLY */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .8rem !important; 
  line-height: 1rem;
}


/* IF Screen Width < 800px */
@media (max-width: 800px) {
  .body-wrap-inner {
    flex-direction: column;
    margin: 0;
    padding: 1rem;
  }

  .sidebar-wrap {
    flex-basis: auto;
    min-width: 100%;
  }

  
  .content-wrap {
    max-width: 100%;
    margin-top: 1rem;
  }

  .content-wrap h2 {
    border: none !important;
  }
}


/* MOBILE PORTRAIT */
@media (orientation: portrait) {
	.body-wrap-inner.mobile {
		margin: 0;
  }

  .board-wrap.mobile h3,          /* ABOUT PAGE ONLY */
  .officers-wrap.mobile h3 {      /* ABOUT PAGE ONLY */
    font-size: .9rem;
  }

  .content-wrap.mobile h2 {
    font-size: 1.4rem;
  }

  .board-wrap.mobile {            /* ABOUT PAGE ONLY */
    width: 50%;
  }

  .officers-wrap.mobile {         /* ABOUT PAGE ONLY */
    width: 50%;
    padding-left: .5rem;
  }
}


/* MOBILE LANDSCAPE */
@media (orientation: landscape) {
  .body-wrap-inner.mobile {
   flex-direction: row; 
  }

  .sidebar-wrap.mobile {
    min-width: 11rem;
    width: fit-content;
  }

  .content-wrap.mobile {
    width: 100%;
    margin-top: 0;
  }

  .content-wrap.mobile p {
    font-size: .8rem;
  }

  .board-wrap.mobile,           /* ABOUT PAGE ONLY */
  .officers-wrap.mobile {       /* ABOUT PAGE ONLY */
    width: 50%;
  }

  .content-wrap.mobile h2 {
    border-bottom: 1px solid lightgrey !important;
  }
}






/* ERROR 404 Page */
h2.error404 {
  font-size: 2rem;
  text-align: center;
  margin: 1rem 1rem 4rem 1rem;
}