.press-li {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: gray;
  font-size: .8rem;
  list-style-type: none;
  margin-bottom: 1rem;
  line-height: 1rem;
}

.press-li a {
  color: black !important;
  text-decoration: none;
  font-size: .8rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.content-wrap .press-li a:hover {
  color: #0D3C96 !important;
}

#press-top-page {
  font-size: .9rem;
}

#press-top-page:hover {
  color: #0D3C96;
  cursor: pointer;
}