/* DESKTOP*********************************************************************/
.home-container {
  height: 100%;
  width: 100%;
  display: contents;
}

.carousel {
	height: 45%;
	position: relative;
	width: 100%;
  	background: black;
}

.slide-img.desktop {
	position: absolute !important;
	height: 100%;
	width: 100% !important;
	max-height: initial !important;
	max-width: initial !important;
}

.slide-img.desktop img {
	width: 100% !important;
	height: 100% !important;
}

/* IF DESKTOP && screen height < 900px */
@media (max-height: 900px) {
	.carousel.desktop {
    height: 70%;
	}
}



/* MOBILE *********************************************************************/
/* IF MOBILE && HORIZONTAL */
@media (orientation: landscape) {
	.carousel.mobile {
    height: 70%;
	}

	.slide-img.mobile {
		position: absolute !important;
		height: 70vh !important;
		width: 100% !important;
		max-width: inherit !important;
    max-height: inherit !important;
	}
	
	.slide-img.mobile img {
		width: 100% !important;
	}
}

/* IF MOBILE && PORTRAIT */
@media (orientation: portrait) {
	.carousel.mobile {
		width: 100%;
    position: relative;
    overflow: hidden;
    height: 70%;
	}

	.slide-img.mobile {
    height: 100%;
		position: absolute !important;		
		left: -16rem;
	}
}






/* HOME CAROUSEL TOP TITLE ****************************************************/
.carousel-title-wrap {
	/* background: rgba(34, 34, 34, 0.3); */
	width: 100%;
	padding: 2rem 0;
}

.carousel-title {
	/* margin-left: 2rem; */
	font-size: 1.4rem;
  color: white;
  max-width: 68.75rem;
  margin: 0 auto;
  padding: 0 2rem;
	font-family: "Michroma", Eurostile, 'Jura', sans-serif;
	animation: 					fadeIn 6s ease-in-out infinite;
	-webkit-animation: 	fadeIn 6s ease-in-out infinite;
	-moz-animation: 		fadeIn 6s ease-in-out infinite;
	-o-animation: 			fadeIn 6s ease-in-out infinite;
	-ms-animationn: 		fadeIn 6s ease-in-out infinite;
}

@keyframes fadeIn {
  0% { opacity: 0 }
	20% { opacity: 1 }
	90% { opacity: 1 }
  100% { opacity: 0 }
}
@-moz-keyframes fadeIn {
  0% { opacity: 0 }
	20% { opacity: 1 }
	90% { opacity: 1 }
  100% { opacity: 0 }
}
@-webkit-keyframes fadeIn {
  0% { opacity: 0 }
	20% { opacity: 1 }
	90% { opacity: 1 }
  100% { opacity: 0 }
}
@-o-keyframes fadeIn {
  0% { opacity: 0 }
	20% { opacity: 1 }
	90% { opacity: 1 }
  100% { opacity: 0 }
}
@-ms-keyframes fadeIn {
  0% { opacity: 0 }
	20% { opacity: 1 }
	90% { opacity: 1 }
  100% { opacity: 0 }
}






/* HOME- CAROUSEL BOTTOM ******************************************************/
.carousel-bottom {
	bottom: 0;
	position: absolute;
  width: 100%;
  background: rgba(34, 34, 34, 0.3);
}

.carousel-tabs {
	display: flex;
  /* justify-content: space-between; */
  max-width: 68.75rem;
  margin: 0 auto;
}

.carousel-tabs__li {
	background: rgb(0, 29, 46);
	padding: 20px 15px;
	flex: 1 1 0px;
	border-right: 1px dashed #2A3235;
	border-left: 1px dashed #2A3235;
	text-align: center;
}

/* Tab Background Hidden */
.carousel-tabs__li.hidden {
	/* background: rgba(34, 34, 34, 0.3); */
	background: rgba(34, 34, 34, 0);
  transition: 3s ease-out;
}

.carousel-tabs li a {
	font-size: .9rem;
	color: white;
	text-transform: uppercase;
	text-decoration: none;
}

.carousel-tabs li a:hover {
	color: grey;
}

/* if width < 800px, make tabs columns */
@media (max-width: 800px) {
	.carousel-tabs {
		flex-direction: column;
	}
	.carousel-tabs__li {
		border-bottom: 1px dashed #2A3235;
	}
}

/* if DESKTOP && width < 800px, make tab font smaller */
@media (max-width: 1000px) {
	.carousel-tabs.desktop li a {
		font-size: .75rem;
	}
}

/* IF MOBILE && PORTRAIT */
@media (orientation: portrait) {
	.carousel-title-wrap.mobile {
		display: none;
	}
	.carousel-tabs.mobile li {
		padding: 15px 0;
	}
}

/* IF MOBILE && LANDSACPE */
@media (orientation: landscape) {
	.carousel-title.mobile {
    /* margin-left: 1rem; */
    padding: 0 1rem;
	}
	.carousel-tabs.mobile {
		flex-direction: row;
	}
	.carousel-tabs.mobile li {
		padding: 15px 0;
	}
	.carousel-tabs.mobile li a {
		font-size: .75rem;
	}
	.carousel-title-wrap.mobile {
		padding: 1rem 0;
	}
}






/* HOME- FEATURED SERVICES ****************************************************/
.gradient-homepage {
	height: 2rem;
	width: 100%;
  background-image: linear-gradient(#00142836, rgba(0,8,255,0));
}

.featured-services-title {
	text-align: center;
	margin-top: 1rem;
}

.featured-services {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
  margin: 0 2rem;
  max-width: 68.75rem;
  margin: 0 auto;
}

.services {
	margin: 2rem 0;
}

.services a {
	text-decoration: none;
	width: 100%;
	height: 100%;
}

.services a div {
	font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, sans-serif;
	background: #001E2F;
	color: white;
	text-transform: uppercase;
	font-size: .9rem;
	/* font-weight: 300; */
	padding: 6px 0;
	text-decoration: none;
	text-align: center;
}

.services a img {
	width: 100%; 
	height: 100%;
	box-shadow: 2px 2px 4px #8D8D8D;
}

