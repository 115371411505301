.footer-container {
  margin-top: 2rem;
}

.footer-top-wrap {
  width: 100%;
  background: #001e2f;
  display: flex;
  justify-content: space-around;
}

.footer-top {
  width: 100%;
  display: flex;
  /* justify-content: space-around; */
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 68.75rem;
  margin: 0 2rem;
}

.footer-bottom-wrap {
  width: 100%;
  background: black;
}

.footer-bottom {
  min-height: 40px;
  width: 100%;
  max-width: 68.75rem;
  margin: 0 auto;
}

.footer-top__boxes {
  /* margin: 1rem; */
  margin: 1rem 0;

  padding: 0 1rem;
  border-left: 1px dashed #2A3235;
}

.footer-top__boxes ul li {
  margin: .5rem 0;
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.footer-top__boxes ul li a {
  text-decoration: none;
  color: #B9B9B9;
}

.footer-top__boxes ul li a:hover {
  color: white;
}

.footer-logo {
  font-family: Eurostile, "Michroma", 'Jura', sans-serif;
  color: white;
  font-size: 1.3rem;
  letter-spacing: .5rem;
}

.footer-top__boxes p {
  font-size: 12px;
  margin-bottom:.25rem;
  color: white;
}

.title {
  color: white !important;
}


/* MOBILE PORTRAIT */
@media (orientation: portrait) {
	.footer-top.mobile {
		flex-direction: column;
    align-content: center;
  }
}
    

.footer-inner {
  margin: 0 2rem;
  font-size: 12px;
  padding-top: .9rem;
}

.footer-companies {
  color: grey;
  display: inline-block;
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid grey;
}

.footer-companies-list, .copyright {
  color: #B9B9B9;
  display: inline-block;
}

.copyright {
  float: right;
}

.footer-companies-list ul li {
  display: inline;
}

.footer-companies-list ul li a {
  margin-right: .75rem;
  text-decoration: none;
  color: #B9B9B9;
}

.footer-companies-list ul li a:hover {
  color: white;
}


/* IF screen width < 890px */
@media (max-width: 890px) {
  .copyright {
    float: none;
    margin: .75rem 0;
  }

  .footer-top {
    justify-content: space-around;
    /* justify-content: space-between; */
    margin: 0 1rem;
  }
}

/* IF screen width < 585px */
@media (max-width: 585px) {
  .footer-companies-list {
    margin-top: .75rem;
  }
}