/* IMAGE TOP (PINUGAY) SECTION **********/
.services-description {
	color: white;
	/* transform: translateY(-284%); */
	/* transform: translateY(-364%); */
	transform: translateY(-334%);
	font-size: 8.1vw;
	font-weight: 300;
	text-align: center;
	/* max-width: 68.75rem; */
	position: absolute;
	width: 100%;
}

.maritime-li li {
	list-style-type: disc;
	font-size: .8rem !important;
	margin-left: 1rem;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 1rem;
}

.maritime-li {
	margin-bottom: .5rem;
}

.services-img {
	width: 100%;
	margin-top: .5rem;
}
