/* ALSO APPLIED TO MONTEMAR  PAGE */
.phc-contact {
  font-family: Eurostile, "Michroma", 'Jura', sans-serif;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: .25rem;
  padding-bottom: .25rem;
  color: #0D3C96;
}

.subsidiary-website-li a {
  text-decoration: none;
  color: #0D3C96;
  font-family: inherit;
} 


/* MONTEMAR PAGE */
.montemar-map {
  width: 100%;
  height: 450px;
}